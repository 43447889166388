import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import Image from "@components/image"

const Card = styled(Link)`
  display: inline-grid;
  text-decoration: none;
  overflow: hidden;
  width: 100%;

  :hover {
    h2,
    a {
      color: ${({ theme }) => theme.color.indigo400};
    }
  }
`

const ImageContainer = styled.div`
  position: relative;
  .klipfolio-image-wrapper {
    height: 300px;
    overflow: hidden;
    position: relative;
  }
  img {
    max-width: 100%;
    padding-right: 1rem;
  }
`

const BackgroundGradient = styled.div`
  background: linear-gradient(180deg, #b0b8fc 0%, #4b57c5 100%);
  margin-left: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  position: absolute;
  height: 100%;
  width: 100%;
  margin-top: 1rem;
  top: 0;
  z-index: -1;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 3rem;
  h2 {
    transition: ${({ theme }) => theme.transition.default};
    font-size: 200%;
    line-height: 150%;
    font-weight: 600;
    color: ${({ theme }) => theme.color.indigo600};
    margin: 0;
  }
  flex: 1;
`

const Summary = styled.p`
  margin: auto 0 0 0;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.color.indigo600};
`

const ReadMore = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.color.indigo500};
  display: block;
  margin-top: 1rem;
  &:hover {
    color: ${({ theme }) => theme.color.indigo400};
  }
`

const ArticleCard = ({ link, title, summary, image }) => {
  return (
    <Card to={link}>
      <ImageContainer>
        <Image file={image} />
        <BackgroundGradient />
      </ImageContainer>
      <TextContainer>
        <h2>{title}</h2>
        <Summary>
          {summary}
          <ReadMore>Read more</ReadMore>
        </Summary>
      </TextContainer>
    </Card>
  )
}

ArticleCard.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

export default ArticleCard

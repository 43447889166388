import React from "react"
import styled, { css, useTheme } from "styled-components"
import Layout from "@components/layout"
import { graphql } from "gatsby"
import ArticleCard from "@components/article/card"
import PropTypes from "prop-types"
import Html from "@components/html"
import Div from "@components/elements/div"
import Anchor from "@components/elements/anchor"
import Paragraph from "@components/elements/paragraph"
import Container from "@components/layout/container"
import Flex from "@components/elements/flex"
import Gradient from "@images/pm-standard-gradient.png"
import Grid from "@components/elements/grid"
import Image from "@components/image"
import Heading from "@components/elements/heading"
import media from "../../styles/media"
import { truncate } from "@utils/truncate"

const ArticleTitle = styled.h1`
  font-size: 300%;
  text-align: left;
  color: ${({ theme }) => theme.color.indigo600};
`

const ArticleSubtitle = styled.h2`
  font-size: 175%;
  font-weight: 500;
  color: ${({ theme }) => theme.color.indigo600};
  margin-bottom: 4rem;
`

const ArticleGrid = styled.div`
  display: flex;
  flex-flow: column;
  gap: 4rem;
`

const ArticleContainer = styled.div`
  max-width: 850px;
  margin: 4rem auto;
`

const ImageGradient = styled.img`
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -50%);
  min-width: 2500px;
  z-index: 0;
  height: auto;
`

const GlassyDiv = styled(Div)`
  background: rgba(255, 255, 255, 0.15);
  border-radius: 16px;
  box-shadow: ${({theme}) => theme.shadow.glassy};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.59);
  ${media.sm`
    ${props =>
      props.reverse &&
      css`
        order: -1;
      `}
  `}
`

const Articles = ({ data, pageContext }) => {
  const { color } = useTheme()
  const articles = data.articles.edges
  const featuredArticles = data.category.featured_articles

  return (
    <Layout fullWidth canonical={pageContext.slug} marginless seo={data.category.seo}>
      <Div>
        <Flex
          background={color.indigo700}
          position="relative"
          overflow="hidden"
          margin="0 0 2rem"
        >
          <ImageGradient src={Gradient} />
          <Container>
            <Grid
              columns="2fr 3fr"
              columnsMd="1fr 1fr"
              columnsSm="1fr"
              gap="4rem"
              gapSm="2rem"
              margin="8rem 0 4rem"
              marginSm="8rem 0 2rem"
            >
              <Div>
                <Paragraph
                  color="white"
                  fontSize="1rem"
                  fontWeight="700"
                  margin="0 0 1rem"
                >
                  Articles
                </Paragraph>
                <Heading fontSize="2rem" color="white" margin="0 0 1rem">
                  <Anchor
                    color="white"
                    link={featuredArticles[0].slug}
                  >
                    {featuredArticles[0].seo.title}
                  </Anchor>
                </Heading>
                <Paragraph lineHeight="1.6" color="white" margin="0 0 2rem">
                  {truncate(featuredArticles[0].seo.description, 200)}
                </Paragraph>
              </Div>
              <GlassyDiv reverse alignSelf="center">
                <Image aspectRatio="5/3" eager radius={1} file={featuredArticles[0].image} />
              </GlassyDiv>
            </Grid>
            <Grid
              gap="2rem"
              columns={"repeat(4, 1fr)"}
              columnsSm="repeat(2, 1fr)"
              margin="0 0 4rem"
            >
              {featuredArticles.map((article, i) => {
                return (
                  i < 4 && (
                    <Div key={`featured-article-${i}`}>
                      <hr />
                      <Paragraph
                        lineHeight="1.5"
                        fontWeight={"700"}
                        fontSize="1.2rem"
                      >
                        <Anchor color="white" link={article.slug}>
                          {article.seo.title}
                        </Anchor>
                      </Paragraph>
                    </Div>
                  )
                )
              })}
            </Grid>
          </Container>
        </Flex>
        </Div>
      <ArticleContainer>
        <ArticleTitle>{data.category.name}</ArticleTitle>
        <ArticleSubtitle>
          <Html inherit parseElements html={data.category.description} />
        </ArticleSubtitle>
        <ArticleGrid>
          {articles.map(({ node: article }) => {
            const date = new Date(article.date.replace(/-/g, "/"))
            const dateString = date.toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })
            return (
              <ArticleCard
                key={article.id}
                image={article.image}
                title={article.seo.title}
                summary={article.seo.description}
                link={article.slug}
                author="Klipfolio"
                date={dateString}
              />
            )
          })}
        </ArticleGrid>
      </ArticleContainer>
    </Layout>
  )
}

Articles.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default Articles

export const pageQuery = graphql`
  query ArticleCategoryQuery($id: String!) {
    category: articleCategories(id: { eq: $id }) {
      seo {
        ...seoData
      }
      name
      heading
      description
      id
      slug
      status
      featured_articles {
        id
        slug
        seo {
          ...seoData
        }
        image {
          id
          title
          cdn
          placeholder
        }
      }
    }
    articles: allArticles(
      filter: { status: { eq: "published" }, category: { id: { eq: $id } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          seo {
            ...seoData
          }
          id
          status
          date
          slug
          image {
            id
            title
            cdn
            placeholder
          }
        }
      }
    }
  }
`
